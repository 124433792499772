import ckeditorconfig from "@/libs/ckeditorconfig";
export default [
  {
    key: "bannerId",
    initKey: "banner",
    label: "field.banner",
    type: "async-single-image",
    path: "tenant",
    pw: 2000,
    ph: 768,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,

  },
  {
    key: "titleEn",
    label: "field.titleEn",
    rules: "required",
    type: "text",
    cols: 6,
  },
  {
    key: "descriptionEn",
    label: "field.descriptionEn",
    type: "ck-editor",
    rules: "required",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },

  {
    key: "titleKm",
    label: "field.titleKm",
    rules: "required",
    type: "text",
    cols: 6,
  },
  {
    key: "descriptionKm",
    label: "field.descriptionKm",
    type: "ck-editor",
    rules: "required",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },

  {
    label: "breadcrumb.englishBusinessPageContent",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "metaTitleEn",
    label: "field.metaTitleEn",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionEn",
    label: "field.metaDescriptionEn",
    type: "textarea",
    cols: 12
  },


  {
    label: "breadcrumb.khmerBusinessPageContent",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "metaTitleKm",
    label: "field.metaTitleKm",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionKm",
    label: "field.metaDescriptionKm",
    type: "textarea",
    cols: 12
  },
  {
    key: "isEnable",
    label: "field.active",
    type: "checkbox",
    cols: "auto",
   },
  ];
  