import ckeditorconfig from "@/libs/ckeditorconfig";

export default [
  {
    key: "bannerId",
    initKey: "banner",
    label: "field.banner",
    type: "async-single-image",
    path: "tenant",
    pw: 2000,
    ph: 768,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,
  },
  {
    key: "titleEn",
    label: "field.titleEn",
    rules: "required",
    type: "text",
    cols: 6,
  },
  {
    key: "descriptionEn",
    label: "field.descriptionEn",
    type: "ck-editor",
    rules: "required",
    config: { ...ckeditorconfig.editorConfig },
    cols: 12,
  },

  {
    key: "titleKm",
    label: "field.titleKm",
    rules: "required",
    type: "text",
    cols: 6,
  },
  {
    key: "descriptionKm",
    label: "field.descriptionKm",
    type: "ck-editor",
    rules: "required",
    config: { ...ckeditorconfig.editorConfig },
    cols: 12,
  },
  {
    label: "breadcrumb.quoteInformation",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "featureImageId",
    initKey: "featureImage",
    label: "field.featureImage",
    type: "async-single-image",
    path: "about",
    pw: 1142,
    ph: 710,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,
  },
  {
    key: "quoteEn",
    label: "field.quoteEn",
    type: "textarea",
    rules: "required",
    cols: 6,
  },
  {
    key: "quoteKm",
    label: "field.quoteKm",
    type: "textarea",
    rules: "required",
    cols: 6,
  },
  // {
  //   key: "quoteEn",
  //   label: "field.quoteEn",
  //   type: "ck-editor",
  //   rules: "required",
  //   config:{...ckeditorconfig.editorConfig},
  //   cols: 12
  // },
  // {
  //   key: "quoteKm",
  //   label: "field.quoteKm",
  //   type: "ck-editor",
  //   rules: "required",
  //   config:{...ckeditorconfig.editorConfig},
  //   cols: 12
  // },

  {
    label: "breadcrumb.englishAboutPageContent",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "contentDescriptionEn",
    label: "field.descriptionEn",
    type: "ck-editor",
    rules: "required",
    config: { ...ckeditorconfig.editorConfig },
    cols: 12,
  },

  {
    key: "metaTitleEn",
    label: "field.metaTitleEn",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionEn",
    label: "field.metaDescriptionEn",
    type: "textarea",
    cols: 12,
  },

  {
    label: "breadcrumb.khmerAboutPageContent",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "contentDescriptionKm",
    label: "field.descriptionKm",
    type: "ck-editor",
    rules: "required",
    config: { ...ckeditorconfig.editorConfig },
    cols: 12,
  },
  {
    key: "metaTitleKm",
    label: "field.metaTitleKm",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionKm",
    label: "field.metaDescriptionKm",
    type: "textarea",
    cols: 12,
  },
  {
    label: "breadcrumb.aboutGallery",
    icon: "ImageIcon",
    type: "divider",
    hasDescriptionDimension: true,
    pw: 1920,
    ph: 880,
  },
  {
    key: "aboutPageGalleries",
    hideLabel: true,
    cols: 12,
  },
  {
    key: "isEnable",
    label: "field.active",
    type: "checkbox",
    cols: "auto",
  },
];
