<template>
  <div>
    <!-- <load-profile></load-profile> -->
    <b-card>
      <validation-observer ref="editForm" #default="{ invalid }">
        <n-form-confirmation
          key="editForm"
          @submit="submit"
          :form="$refs.editForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data" :initValue="initData">
            <template #contactPageEnquiries>
              <div class="pt-2">
                <b-col
                  lg="12"
                  md="12"
                  class="p-0"
                  v-for="(enquiry, idx) in tempContactPageEnquiries"
                  :key="`enquiry-${idx}`"
                >
                  <b-row>
                    <b-col lg="12">
                      <b-row>
                        <b-col lg="6">
                          <validation-provider
                            #default="{ errors }"
                            :vid="`${enquiry.titleEn}`"
                            :name="$t('field.titleEn')"
                            rules="required"
                          >
                            <label class="mt-2"
                              >{{ $t("field.titleEn") }}
                              <span class="text-muted">
                                - {{ $t("field.optional") }}
                              </span>
                            </label>
                            <b-form-input
                              :placeholder="$t('field.titleEn')"
                              autocomplete="off"
                              v-model="enquiry.titleEn"
                              :class="errors.length > 0 ? 'is-invalid' : null"
                            />
                          </validation-provider>
                        </b-col>
                        <b-col lg="6">
                          <validation-provider
                            #default="{ errors }"
                            :vid="`${enquiry.titleKm}`"
                            :name="$t('field.titleKm')"
                            rules="required"
                          >
                            <label class="mt-2"
                              >{{ $t('field.titleKm') }}
                              <span class="text-muted">
                                - {{ $t("field.optional") }}
                              </span>
                            </label>
                            <b-form-input
                              :placeholder="$t('field.titleKm')"
                              autocomplete="off"
                              v-model="enquiry.titleKm"
                              :class="errors.length > 0 ? 'is-invalid' : null"
                            />
                          </validation-provider>
                        </b-col>
                        <b-col lg="6">
                          <validation-provider
                            #default="{ errors }"
                            :vid="`${enquiry.contactInformationEn}`"
                            :name="$t('field.contactInformationEn')"
                            rules="required"
                          >
                            <label class="mt-2"
                              >{{ $t("field.contactInformationEn") }}
                              <span class="text-muted">
                                - {{ $t("field.optional") }}
                              </span>
                            </label>
                            <ckeditor
                              tag-name="textarea"
                              v-model="enquiry.contactInformationEn"
                              :name="enquiry.contactInformationEn"
                              :config="enquiry.config"
                              :class="errors.length > 0 ? 'ck-invalid' : null"
                            >
                            </ckeditor>
                          </validation-provider>
                        </b-col>
                        <b-col lg="6">
                          <validation-provider
                            #default="{ errors }"
                            :vid="`${enquiry.contactInformationKm}`"
                            :name="$t('field.contactInformationKm')"
                            rules="required"
                          >
                            <label class="mt-2"
                              >{{ $t("field.contactInformationKm") }}
                              <span class="text-muted">
                                - {{ $t("field.optional") }}
                              </span>
                            </label>
                            <ckeditor
                              tag-name="textarea"
                              v-model="enquiry.contactInformationKm"
                              :name="enquiry.contactInformationKm"
                              :config="enquiry.config"
                              :class="errors.length > 0 ? 'ck-invalid' : null"
                            >
                            </ckeditor>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col align-self="end" class="text-right">
                      <b-button
                        variant="outline-danger"
                        class="mt-1"
                        @click="removeEnquiry(idx)"
                      >
                        <feather-icon icon="XIcon" class="mr-1"></feather-icon>
                        <span>{{ $t("button.delete") }}</span>
                      </b-button>
                    </b-col>
                  </b-row>
                  <hr class="mb-2" />
                </b-col>
                <b-col class="p-0">
                  <b-button
                    variant="primary"
                    class="mb-2"
                    @click="addMoreEnquiry"
                  >
                    <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
                    <span>{{ $t("button.addEnquiry") }}</span>
                  </b-button>
                </b-col>
              </div>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :resource="resource"
                :route="route"
                :hide-create="true"
                v-if="$can('update', resource)"
              ></n-button-save-option>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import ckeditorconfig from "@/libs/ckeditorconfig";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";
import NAsyncSingleImageUploader from "@/components/NAsyncSingleImageUploader";
import draggable from "vuedraggable";
const ContactPageRepository = Repository.get("contactPage");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BFormTextarea,
    draggable,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
    NAsyncSingleImageUploader,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      enquiryKey: 1,
      enquiryIndex: 1,
      tempContactPageEnquiries: [],
      contactPageId: null,
      data: {
        bannerId: null,

        isEnable: true,
        titleEn: null,
        descriptionEn: "",
        metaTitleEn: null,
        metaDescriptionEn: null,
        titleKm: null,
        descriptionKm: "",
        metaTitleKm: null,
        metaDescriptionKm: null,

        contactPageContents: [],
        contactPageEnquiries: [],
      },
      initData: {},
      loading: false,
      type: 2,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      ContactPageRepository.show({})
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.initData = {
              ...data,
            };
            this.contactPageId = data.id;

            data.contactPageContents.forEach((content) => {
              if (content.locale == "en") {
                this.data.metaTitleEn = content.metaTitle;
                this.data.metaDescriptionEn = content.metaDescription;
              } else if (content.locale == "km") {
                this.data.metaTitleKm = content.metaTitle;
                this.data.metaDescriptionKm = content.metaDescription;
              }
            });

            data.contactPageEnquiries.forEach((enquiry, index) => {
              this.enquiryIndex = index + 1;
              if (index % 2 == 0) {
                this.tempContactPageEnquiries.push({
                  titleEn: enquiry.title,
                  contactInformationEn: enquiry.contactInformation,
                  titleKm: data.contactPageEnquiries[index + 1].title,
                  contactInformationKm: data.contactPageEnquiries[index + 1].contactInformation,
                  config: { ...ckeditorconfig.editorConfig },

                });
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit(type) {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          type = this.type;
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;
      this.data.contactPageContents = [];
      this.data.contactPageEnquiries = [];

      this.data.contactPageContents.push(
        {
          locale: "en",
          metaTitle: this.data.metaTitleEn,
          metaDescription: this.data.metaDescriptionEn,
        },
        {
          locale: "km",
          metaTitle: this.data.metaTitleKm,
          metaDescription: this.data.metaDescriptionKm,
        }
      );

      this.tempContactPageEnquiries.forEach((enquiry, index) => {
        this.data.contactPageEnquiries.push(
          {
            locale: "en",
            title: enquiry.titleEn,
            contactInformation: enquiry.contactInformationEn,
          },
          {
            locale: "km",
            title: enquiry.titleKm,
            contactInformation: enquiry.contactInformationKm,
          }
        );
      });
      if (this.contactPageId !== null) {
        ContactPageRepository.update(this.contactPageId, this.data)
          .then((response) => {
            // this.$refs.btnSubmit.afterSave(type, response.data.data.id);
          })
          .catch((error) => {
            if (error.response?.status == 422) {
              this.$refs.editForm.setErrors(error.response?.data?.message);
            }
          })
          .then(() => {
            this.loading = false;
          });
      } else {
        ContactPageRepository.create(this.data)
          .then((response) => {
            this.contactPageId = response.data.data.id;
            // this.$refs.btnSubmit.afterSave(type, response.data.data.id);
          })
          .catch((error) => {
            if (error.response?.status == 422) {
              this.$refs.createForm.setErrors(error.response?.data?.message);
            }
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    addMoreEnquiry() {
      this.enquiryIndex++;
      let enquiry = {
        titleEn: "",
        titleKm: "",
        contactInformationEn: "",
        contactInformationKm: "",
        config: { ...ckeditorconfig.editorConfig },
      };
      this.tempContactPageEnquiries.push(enquiry);
    },
    removeEnquiry(idx) {
      this.tempContactPageEnquiries.splice(idx, 1);
      this.enquiryKey++;
    },
    back() {
      this.$router.push({
        name: `update-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    const resource = "contact-page";
    const route = "contact-page";

    return { fields, resource, route };
  },
};
</script>