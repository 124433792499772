<template>
    <div>
      <!-- <load-profile></load-profile> -->
      <b-card>
        <validation-observer ref="editForm" #default="{ invalid }">
          <n-form-confirmation
            key="editForm"
            @submit="submit"
            :form="$refs.editForm"
            :disabled="invalid"
          >
            <n-input :fields="fields" v-model="data" :initValue="initData">
              <template #careerPageCoreValues>
                <div class="pt-2">
        
                    <b-col
                      lg="12"
                      md="12"
                      class="p-0"
                      v-for="(coreValue, idx) in tempCareerPageCoreValues"
                      :key="`coreValue-${idx}`"
                    >
                      <b-row>
                        <b-col lg="12">
                          <label
                            >{{ $t("field.image") }}
                            <span class="text-muted">
                              - {{ $t("field.optional") }}
                            </span>
                          </label>
                          <n-async-single-image-uploader
                            :key="`image-upload-${idx}-${coreValueKey}`"
                            path="core-value"
                            ph="240"
                            pw="240"
                            :disableUploadHistory="true"
                            :fullWidth="false"
                            :image="coreValue.image"
                            v-model="coreValue.imageId"
                            @change="onUploadChange($event, idx)"
                          />

                          <b-row>
                            <b-col lg="6">
                              <validation-provider
                                #default="{ errors }"
                                :vid="`${coreValue.titleEn}`"
                                :name="$t('field.titleEn')"
                                rules="required"
                              >
                                <label class="mt-2"
                                  >{{ $t("field.titleEn") }}
                                  <span class="text-muted">
                                    - {{ $t("field.optional") }}
                                  </span>
                                </label>
                                <b-form-input
                                  placeholder="English Name"
                                  autocomplete="off"
                                  v-model="coreValue.titleEn"
                                  :class="
                                    errors.length > 0 ? 'is-invalid' : null
                                  "
                                />
                              </validation-provider>
                            </b-col>
                            <b-col lg="6">
                              <validation-provider
                                #default="{ errors }"
                                :vid="`${coreValue.titleKm}`"
                                :name="$t('field.titleKm')"
                                rules="required"
                              >
                                <label class="mt-2"
                                  >{{ $t("field.titleKm") }}
                                  <span class="text-muted">
                                    - {{ $t("field.optional") }}
                                  </span>
                                </label>
                                <b-form-input
                                  placeholder="Khmer Name"
                                  autocomplete="off"
                                  v-model="coreValue.titleKm"
                                  :class="
                                    errors.length > 0 ? 'is-invalid' : null
                                  "
                                />
                              </validation-provider>
                            </b-col>
                            <b-col lg="6">
                              <validation-provider
                                #default="{ errors }"
                                :vid="`${coreValue.descriptionEn}`"
                                :name="$t('field.descriptionEn')"
                                rules="required"
                              >
                                <label class="mt-2"
                                  >{{ $t("field.englishDescription") }}
                                  <span class="text-muted">
                                    - {{ $t("field.optional") }}
                                  </span>
                                </label>
                                <b-form-textarea
                                  id="textarea"
                                  v-model="coreValue.descriptionEn"
                                  :placeholder="$t('field.englishDescription')"
                                  rows="3"
                                  max-rows="6"
                                  :class="
                                    errors.length > 0 ? 'is-invalid' : null
                                  "
                                ></b-form-textarea>
                              </validation-provider>
                            </b-col>
                            <b-col lg="6">
                              <validation-provider
                                #default="{ errors }"
                                :vid="`${coreValue.descriptionKm}`"
                                :name="$t('field.descriptionKm')"
                                rules="required"
                              >
                                <label class="mt-2"
                                  >{{ $t("field.khmerDescription") }}
                                  <span class="text-muted">
                                    - {{ $t("field.optional") }}
                                  </span>
                                </label>
                                <b-form-textarea
                                  id="textarea"
                                  v-model="coreValue.descriptionKm"
                                  :placeholder="$t('field.khmerDescription')"
                                  rows="3"
                                  max-rows="6"
                                  :class="
                                    errors.length > 0 ? 'is-invalid' : null
                                  "
                                ></b-form-textarea>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col align-self="end" class="text-right">
                          <b-button
                            variant="outline-danger"
                            class="mt-1"
                            @click="removeCoreValue(idx)"
                          >
                            <feather-icon
                              icon="XIcon"
                              class="mr-1"
                            ></feather-icon>
                            <span>{{ $t("button.delete") }}</span>
                          </b-button>
                        </b-col>
                      </b-row>
                      <hr class="mb-2" />
                    </b-col>
 
                <b-col class="p-0">
                  <b-button
                    variant="primary"
                    class="mb-2"
                    @click="addMoreCoreValue"
                  >
                    <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
                    <span>{{ $t("button.addMore") }}</span>
                  </b-button>
                </b-col>
              </div>
              </template>
            </n-input>
            <b-row>
              <b-col cols="12" class="text-right">
                <n-button-save-option
                  ref="btnSubmit"
                  @submit="submit"
                  @save="save"
                  :loading="loading"
                  :resource="resource"
                  :route="route"
                  :hide-create="true"
                  v-if="$can('update', resource)"
                ></n-button-save-option>
              </b-col>
            </b-row>
          </n-form-confirmation>
        </validation-observer>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BFormTextarea,
  } from "bootstrap-vue";
  import Repository from "@/repositories/RepositoryFactory";
  import Ripple from "vue-ripple-directive";
  import NButtonLoading from "@/components/NButtonLoading";
  import NFormConfirmation from "@/components/NFormConfirmation";
  import NInput from "@/components/NInput";
  import FormInput from "./formInput";
  import NButtonSaveOption from "@/components/NButtonSaveOption";
  import NAsyncSingleImageUploader from "@/components/NAsyncSingleImageUploader";
  const CareerPageRepository = Repository.get("careerPage");
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BFormInput,
      BButton,
      BFormTextarea,
      NButtonLoading,
      NFormConfirmation,
      NInput,
      NButtonSaveOption,
      NAsyncSingleImageUploader,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
       coreValueKey: 1,
       coreValueIndex: 1,
        tempCareerPageCoreValues: [],
        careerPageId: null,
        data: {
          bannerId: null,
          featureImageId:null, 
          titleEn:null,
          titleKm:null,
          descriptionEn:"",
          descriptionKm:"",
          isEnable: true,

          contentTitleEn: null,
          contentTitleKm:null,
          contentDescriptionEn:"",
          contentDescriptionKm:"",
          metaTitleEn:null,
          metaTitleKm: null,
          metaDescriptionEn: null,
          metaDescriptionKm: null,
  
          careerPageContents: [],
          careerPageCoreValues: [],
        },
        initData: {},
        loading: false,
        type: 2,
      };
    },
    mounted() {
      this.showData();
    },
    methods: {
      showData() {
        CareerPageRepository.show({})
          .then((response) => {
            const data = response?.data?.data;
            if (data) {
              this.initData = {
                ...data,
              };
              this.careerPageId = data.id;
  
              data.careerPageContents.forEach((content) => {
                if (content.locale == "en") {
                  this.data.contentTitleEn = content.title;  
                  this.data.contentDescriptionEn = content.description;  
                  this.data.metaTitleEn = content.metaTitle;
                  this.data.metaDescriptionEn = content.metaDescription;
                } else if (content.locale == "km") {
                  this.data.contentTitleKm = content.title;  
                  this.data.contentDescriptionKm = content.description;    
                  this.data.metaTitleKm = content.metaTitle;
                  this.data.metaDescriptionKm = content.metaDescription;
                }
              });
  
              data.careerPageCoreValues.forEach((coreValue, index) => {
                this.coreValueIndex = index + 1;
                if (index % 2 == 0) {
                  this.tempCareerPageCoreValues.push({
                    imageId: coreValue.imageId,
                    image: coreValue.image,
                    titleEn: coreValue.title,
                    descriptionEn: coreValue.description,
                    titleKm: data.careerPageCoreValues[index + 1].title,
                    descriptionKm: data.careerPageCoreValues[index + 1].description,
                  });
                }
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      submit(type) {
        this.$refs.editForm.validate().then((success) => {
          if (success) {
            type = this.type;
            this.$refs.btnSubmit.confirm(type);
          }
        });
      },
      save(type) {
        this.loading = true;
        this.data.careerPageContents = [];
        this.data.careerPageCoreValues = [];
  
        this.data.careerPageContents.push(
          {
            locale: "en",
            title:this.data.contentTitleEn,
            description: this.data.contentDescriptionEn,
            metaTitle: this.data.metaTitleEn,
            metaDescription: this.data.metaDescriptionEn,
          },
          {
            locale: "km",
            title:this.data.contentTitleKm,
            description: this.data.contentDescriptionKm,
            metaTitle: this.data.metaTitleKm,
            metaDescription: this.data.metaDescriptionKm,
          }
        );
  
        this.tempCareerPageCoreValues.forEach((coreValue, index) => {
          this.data.careerPageCoreValues.push(
                {
                locale: "en",
                imageId: coreValue.imageId,
                title: coreValue.titleEn,
                description: coreValue.descriptionEn,
            },
            {
                locale: "km",
                imageId: coreValue.imageId,
                title: coreValue.titleKm,
                description: coreValue.descriptionKm,
            }
          );
        });
        if (this.careerPageId !== null) {
        CareerPageRepository.update(this.careerPageId, this.data)
            .then((response) => {
              // this.$refs.btnSubmit.afterSave(type, response.data.data.id);
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.editForm.setErrors(error.response?.data?.message);
              }
            })
            .then(() => {
              this.loading = false;
            });
        } else {
        CareerPageRepository.create(this.data)
            .then((response) => {
              this.careerPageId = response.data.data.id;
              // this.$refs.btnSubmit.afterSave(type, response.data.data.id);
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.createForm.setErrors(error.response?.data?.message);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      },
      addMoreCoreValue() {
        this.coreValueIndex++;
        let coreValue = {
            imageId: null,
            image: "",
            titleEn: "",
            titleKm: "",
            descriptionEn: "",
            descriptionKm: "",
        };
        this.tempCareerPageCoreValues.push(coreValue);
      },
      removeCoreValue(idx) {
        this.tempCareerPageCoreValues.splice(idx, 1);
        this.coreValueKey++;
      },
      back() {
        this.$router.push({
          name: `update-${this.route}`,
        });
      },
    },
    setup() {
      const fields = FormInput;
      const resource = "career-page";
      const route = "career-page";
  
      return { fields, resource, route };
    },
  };
  </script>