<template>
  <div>
    <!-- <load-profile></load-profile> -->
    <b-card>
      <validation-observer ref="editForm" #default="{ invalid }">
        <n-form-confirmation
          key="editForm"
          @submit="submit"
          :form="$refs.editForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data" :initValue="initData">
            <template #aboutPageGalleries>
              <div class="mt-2">
                <draggable
                  v-model="data.aboutPageGalleries"
                  :move="onMove"
                  :start="true"
                  :end="true"
                  @change="onDragChange"
                >
                  <transition-group type="transition" :name="'flip-list'">
                    <div
                      class="list-group-item"
                      v-for="(gallery, idx) in data.aboutPageGalleries"
                      :key="gallery.id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :vid="`${gallery.id}-${idx}`"
                        :name="$t('field.image')"
                        rules="required"
                      >
                        <n-async-single-image-uploader
                          class="gallery"
                          :key="`image-upload-${idx}-${key}`"
                          path="about-page-gallery"
                          ph="240"
                          pw="240"
                          :disableUploadHistory="true"
                          :disableSpacer="false"
                          :fullWidth="false"
                          :image="gallery.image"
                          v-model="gallery.imageId"
                          @change="onUploadChange($event, idx)"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        ></n-async-single-image-uploader>
                        <feather-icon
                          @click="removeGallery(idx)"
                          class="close-icon"
                          icon="XIcon"
                          size="18"
                        ></feather-icon>
                      </validation-provider>
                    </div>
                  </transition-group>
                </draggable>
                <hr v-show="data.aboutPageGalleries.length >= 1" />
                <b-button
                  variant="primary"
                  @click="addMoreGallery"
                  class="mb-2"
                >
                  <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
                  <span>{{ $t("button.addGallery") }}</span>
                </b-button>
              </div>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :resource="resource"
                :route="route"
                :hide-create="true"
                v-if="$can('update', resource)"
              ></n-button-save-option>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";
import NAsyncSingleImageUploader from "@/components/NAsyncSingleImageUploader";
import draggable from "vuedraggable";

const AboutPageRepository = Repository.get("aboutPage");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BFormTextarea,
    draggable,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
    NAsyncSingleImageUploader,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      aboutPageId: null,
      key: 1,
      index: 0,
      data: {
        bannerId: null,
        titleEn: null,
        descriptionEn: "",
        titleKm: null,
        descriptionKm: "",
        bannerId: null,
        quoteEn: "",
        quoteKm: "",
        isEnable: true,

        contentDescriptionEn: "",
        metaTitleEn: null,
        metaDescriptionEn: null,

        contentDescriptionEn: "",
        metaTitleKm: null,
        metaDescriptionKm: null,

        aboutPageContents: [],

        aboutPageGalleries: [],
      },
      initData: {},
      loading: false,
      type: 2,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    onDragChange() {
      this.data.aboutPageGalleries.forEach((item, index) => {
        item.sequenceOrder = index + 1;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    onUploadChange(event, idx) {
      const index = this.data.aboutPageGalleries.findIndex((element, i) => {
        return idx == i;
      });
      if (index != -1) {
        this.data.aboutPageGalleries[index].image = event.fileUrl;
      }
    },
    show() {
      AboutPageRepository.show({})
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.initData = {
              ...data,
            };
            this.aboutPageId = data.id;
            data.aboutPageContents.forEach((content) => {
              if (content.locale == "en") {
                this.data.contentDescriptionEn = content.description;
                this.data.metaTitleEn = content.metaTitle;
                this.data.metaDescriptionEn = content.metaDescription;
              } else if (content.locale == "km") {
                this.data.contentDescriptionKm = content.description;
                this.data.metaTitleKm = content.metaTitle;
                this.data.metaDescriptionKm = content.metaDescription;
              }
            });

            data.aboutPageGalleries.forEach((gallery) => {
              gallery.id = this.index++;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit(type) {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          type = this.type;
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;
      this.data.aboutPageContents = [];
      this.data.aboutPageContents.push(
        {
          locale: "en",
          description: this.data.contentDescriptionEn,
          metaTitle: this.data.metaTitleEn,
          metaDescription: this.data.metaDescriptionEn,
        },
        {
          locale: "km",
          description: this.data.contentDescriptionKm,
          metaTitle: this.data.metaTitleKm,
          metaDescription: this.data.metaDescriptionKm,
        }
      );
      if (this.aboutPageId !== null) {
        AboutPageRepository.update(this.aboutPageId, this.data)
          .then((response) => {
            // this.$refs.btnSubmit.afterSave(type, response.data.data.id);
          })
          .catch((error) => {
            if (error.response?.status == 422) {
              this.$refs.editForm.setErrors(error.response?.data?.message);
            }
          })
          .then(() => {
            this.loading = false;
          });
      } else {
        AboutPageRepository.create(this.data)
          .then((response) => {
            this.aboutPageId = response.data.data.id;
            // this.$refs.btnSubmit.afterSave(type, response.data.data.id);
          })
          .catch((error) => {
            if (error.response?.status == 422) {
              this.$refs.createForm.setErrors(error.response?.data?.message);
            }
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    addMoreGallery() {
      this.index++;
      let gallery = {
        id: this.index,
        imageId: null,
        image: "",
        sequenceOrder: this.index,
      };
      this.data.aboutPageGalleries.push(gallery);
    },
    removeGallery(index) {
      this.data.aboutPageGalleries.splice(index, 1);
      this.key++;
      this.index--;
      this.onDragChange();
    },
  },
  setup() {
    const fields = FormInput;
    const resource = "about-page";
    const route = "about-page";

    return { fields, route, resource };
  },
};
</script>
