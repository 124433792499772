var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"editForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"editForm",attrs:{"form":_vm.$refs.editForm,"disabled":invalid},on:{"submit":_vm.submit}},[_c('n-input',{attrs:{"fields":_vm.fields,"initValue":_vm.initData},scopedSlots:_vm._u([{key:"contactPageEnquiries",fn:function(){return [_c('div',{staticClass:"pt-2"},[_vm._l((_vm.tempContactPageEnquiries),function(enquiry,idx){return _c('b-col',{key:("enquiry-" + idx),staticClass:"p-0",attrs:{"lg":"12","md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":("" + (enquiry.titleEn)),"name":_vm.$t('field.titleEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("field.titleEn"))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.titleEn'),"autocomplete":"off"},model:{value:(enquiry.titleEn),callback:function ($$v) {_vm.$set(enquiry, "titleEn", $$v)},expression:"enquiry.titleEn"}})]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":("" + (enquiry.titleKm)),"name":_vm.$t('field.titleKm'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t('field.titleKm'))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.titleKm'),"autocomplete":"off"},model:{value:(enquiry.titleKm),callback:function ($$v) {_vm.$set(enquiry, "titleKm", $$v)},expression:"enquiry.titleKm"}})]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":("" + (enquiry.contactInformationEn)),"name":_vm.$t('field.contactInformationEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("field.contactInformationEn"))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('ckeditor',{class:errors.length > 0 ? 'ck-invalid' : null,attrs:{"tag-name":"textarea","name":enquiry.contactInformationEn,"config":enquiry.config},model:{value:(enquiry.contactInformationEn),callback:function ($$v) {_vm.$set(enquiry, "contactInformationEn", $$v)},expression:"enquiry.contactInformationEn"}})]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":("" + (enquiry.contactInformationKm)),"name":_vm.$t('field.contactInformationKm'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("field.contactInformationKm"))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('ckeditor',{class:errors.length > 0 ? 'ck-invalid' : null,attrs:{"tag-name":"textarea","name":enquiry.contactInformationKm,"config":enquiry.config},model:{value:(enquiry.contactInformationKm),callback:function ($$v) {_vm.$set(enquiry, "contactInformationKm", $$v)},expression:"enquiry.contactInformationKm"}})]}}],null,true)})],1)],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"align-self":"end"}},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeEnquiry(idx)}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.delete")))])],1)],1)],1),_c('hr',{staticClass:"mb-2"})],1)}),_c('b-col',{staticClass:"p-0"},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":_vm.addMoreEnquiry}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.addEnquiry")))])],1)],1)],2)]},proxy:true}],null,true),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}}),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.$can('update', _vm.resource))?_c('n-button-save-option',{ref:"btnSubmit",attrs:{"loading":_vm.loading,"resource":_vm.resource,"route":_vm.route,"hide-create":true},on:{"submit":_vm.submit,"save":_vm.save}}):_vm._e()],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }