<template>
  <div>
    <load-profile></load-profile>
    <b-tabs pills card class="tab-card">
      <b-tab active>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>{{ $t("breadcrumb.aboutPage") }}</span>
        </template>
        <update-about-page></update-about-page>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="MailIcon" />
          <span>{{ $t("breadcrumb.contactPage") }}</span>
        </template>
        <update-contact-page></update-contact-page>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ImageIcon" />
          <span>{{ $t("breadcrumb.galleryPage") }}</span>
        </template>
        <update-gallery-page></update-gallery-page>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BoxIcon" />
          <span>{{ $t("breadcrumb.directoryPage") }}</span>
        </template>
        <update-directory-page></update-directory-page>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="ShoppingBagIcon" />
          <span>{{ $t("breadcrumb.shoppingPage") }}</span>
        </template>
        <update-shopping-page></update-shopping-page>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BarChartIcon" />
          <span>{{ $t("breadcrumb.businessPage") }}</span>
        </template>
        <update-business-page></update-business-page>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BookOpenIcon" />
          <span>{{ $t("breadcrumb.articlePage") }}</span>
        </template>
        <update-article-page></update-article-page>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BriefcaseIcon" />
          <span>{{ $t("breadcrumb.careerPage") }}</span>
        </template>
        <update-career-page></update-career-page>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import BCardCode from "@core/components/b-card-code";
import { BTabs, BTab, BCardText, BCard } from "bootstrap-vue";
import UpdateShoppingPage from "./shoppingPage/UpdateShoppingPage.vue";
import UpdateDirectoryPage from "./directoryPage/UpdateDirectoryPage.vue";
import UpdateBusinessPage from "./businessPage/UpdateBusinessPage.vue";
import UpdateAboutPage from "./aboutPage/UpdateAboutPage.vue";
import UpdateContactPage from "./contactPage/UpdateContactPage.vue";
import UpdateCareerPage from "./careerPage/UpdateCareerPage.vue";
import UpdateGalleryPage from "./galleryPage/UpdateGalleryPage.vue";
import UpdateArticlePage from "./articlePage/UpdateArticlePage.vue";
export default {
  components: {
    BCardCode,
    BTabs,
    BTab,
    BCardText,
    BCard,
    UpdateShoppingPage,
    UpdateDirectoryPage,
    UpdateBusinessPage,
    UpdateAboutPage,
    UpdateContactPage,
    UpdateCareerPage,
    UpdateGalleryPage,
    UpdateArticlePage,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  setup() {
    const resource = "page-content";
    const route = "page-content";

    return { resource };
  },
};
</script>
