export default [
  {
    key: "bannerId",
    initKey: "banner",
    label: "field.banner",
    type: "async-single-image",
    path: "article-page",
    pw: 2000,
    ph: 768,
    disableUploadHistory: true,
    cols: 12,
  },
  {
    label: "breadcrumb.articlePageContentEn",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "metaTitleEn",
    label: "field.metaTitleEn",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionEn",
    label: "field.metaDescriptionEn",
    type: "textarea",
    cols: 12,
  },
  {
    label: "breadcrumb.articlePageContentKm",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "metaTitleKm",
    label: "field.metaTitleKm",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionKm",
    label: "field.metaDescriptionKm",
    type: "textarea",
    cols: 12,
  },
];
