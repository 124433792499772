var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"editForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"editForm",attrs:{"form":_vm.$refs.editForm,"disabled":invalid},on:{"submit":_vm.submit}},[_c('n-input',{attrs:{"fields":_vm.fields,"initValue":_vm.initData},scopedSlots:_vm._u([{key:"careerPageCoreValues",fn:function(){return [_c('div',{staticClass:"pt-2"},[_vm._l((_vm.tempCareerPageCoreValues),function(coreValue,idx){return _c('b-col',{key:("coreValue-" + idx),staticClass:"p-0",attrs:{"lg":"12","md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("field.image"))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('n-async-single-image-uploader',{key:("image-upload-" + idx + "-" + _vm.coreValueKey),attrs:{"path":"core-value","ph":"240","pw":"240","disableUploadHistory":true,"fullWidth":false,"image":coreValue.image},on:{"change":function($event){return _vm.onUploadChange($event, idx)}},model:{value:(coreValue.imageId),callback:function ($$v) {_vm.$set(coreValue, "imageId", $$v)},expression:"coreValue.imageId"}}),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":("" + (coreValue.titleEn)),"name":_vm.$t('field.titleEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("field.titleEn"))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":"English Name","autocomplete":"off"},model:{value:(coreValue.titleEn),callback:function ($$v) {_vm.$set(coreValue, "titleEn", $$v)},expression:"coreValue.titleEn"}})]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":("" + (coreValue.titleKm)),"name":_vm.$t('field.titleKm'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("field.titleKm"))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":"Khmer Name","autocomplete":"off"},model:{value:(coreValue.titleKm),callback:function ($$v) {_vm.$set(coreValue, "titleKm", $$v)},expression:"coreValue.titleKm"}})]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":("" + (coreValue.descriptionEn)),"name":_vm.$t('field.descriptionEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("field.englishDescription"))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('b-form-textarea',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"textarea","placeholder":_vm.$t('field.englishDescription'),"rows":"3","max-rows":"6"},model:{value:(coreValue.descriptionEn),callback:function ($$v) {_vm.$set(coreValue, "descriptionEn", $$v)},expression:"coreValue.descriptionEn"}})]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":("" + (coreValue.descriptionKm)),"name":_vm.$t('field.descriptionKm'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("field.khmerDescription"))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('b-form-textarea',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"textarea","placeholder":_vm.$t('field.khmerDescription'),"rows":"3","max-rows":"6"},model:{value:(coreValue.descriptionKm),callback:function ($$v) {_vm.$set(coreValue, "descriptionKm", $$v)},expression:"coreValue.descriptionKm"}})]}}],null,true)})],1)],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"align-self":"end"}},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeCoreValue(idx)}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.delete")))])],1)],1)],1),_c('hr',{staticClass:"mb-2"})],1)}),_c('b-col',{staticClass:"p-0"},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":_vm.addMoreCoreValue}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.addMore")))])],1)],1)],2)]},proxy:true}],null,true),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}}),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.$can('update', _vm.resource))?_c('n-button-save-option',{ref:"btnSubmit",attrs:{"loading":_vm.loading,"resource":_vm.resource,"route":_vm.route,"hide-create":true},on:{"submit":_vm.submit,"save":_vm.save}}):_vm._e()],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }