import ckeditorconfig from "@/libs/ckeditorconfig";
export default [
  {
    key: "bannerId",
    initKey: "banner",
    label: "field.banner",
    type: "async-single-image",
    path: "tenant",
    pw: 2000,
    ph: 768,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,

  },
  {
    key: "titleEn",
    label: "field.titleEn",
    rules: "required",
    type: "text",
    cols: 6,
  },
  {
    key: "descriptionEn",
    label: "field.descriptionEn",
    type: "ck-editor",
    rules: "required",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },

  {
    key: "titleKm",
    label: "field.titleKm",
    rules: "required",
    type: "text",
    cols: 6,
  },
  {
    key: "descriptionKm",
    label: "field.descriptionKm",
    type: "ck-editor",
    rules: "required",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },
  {
    key: "featureImageId",
    initKey: "featureImage",
    label: "field.featureImage",
    type: "async-single-image",
    path: "about",
    pw: 200,
    ph: 200,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,

  },
    {
      label: "breadcrumb.englishCareerPageContent",
      icon: "FileTextIcon",
      type: "divider",
    }, 
    {
        key: "contentTitleEn",
        label: "field.titleEn",
        type: "text",
        cols: 6,
    },
    {
        key: "contentDescriptionEn",
        label: "field.descriptionEn",
        type: "ck-editor",
        rules: "required",
        config:{...ckeditorconfig.editorConfig},
        cols: 12
      },
    {
      key: "metaTitleEn",
      label: "field.metaTitleEn",
      type: "text",
      cols: 6,
    },
    {
      key: "metaDescriptionEn",
      label: "field.metaDescriptionEn",
      type: "textarea",
      cols: 12
    },
  
  
    {
      label: "breadcrumb.khmerCareerPageContent",
      icon: "FileTextIcon",
      type: "divider",
    },
    {
        key: "contentTitleKm",
        label: "field.titleEn",
        type: "text",
        cols: 6,
    },
    {
        key: "contentDescriptionKm",
        label: "field.descriptionEn",
        type: "ck-editor",
        rules: "required",
        config:{...ckeditorconfig.editorConfig},
        cols: 12
      },
    {
      key: "metaTitleKm",
      label: "field.metaTitleKm",
      type: "text",
      cols: 6,
    },
    {
      key: "metaDescriptionKm",
      label: "field.metaDescriptionKm",
      type: "textarea",
      cols: 12
    },
  
    {
      label: "breadcrumb.careerPageCoreValues",
      icon: "FileTextIcon",
      type: "divider",
    },
    {
      key:"careerPageCoreValues",
      hideLabel:true,
      type: "slot",
      cols:12,
    },
    {
      key: "isEnable",
      label: "field.active",
      type: "checkbox",
      cols: "auto",
    },
  ];
  