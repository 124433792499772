<template>
    <div>
      <!-- <load-profile></load-profile> -->
      <b-card>
        <validation-observer ref="editForm" #default="{ invalid }">
          <n-form-confirmation
            key="editForm"
            @submit="submit"
            :form="$refs.editForm"
            :disabled="invalid"
          >
            <n-input :fields="fields" v-model="data" :initValue="initData"> </n-input>
            <b-row>
              <b-col cols="12" class="text-right">
                <n-button-save-option
                  ref="btnSubmit"
                  @submit="submit"
                  @save="save"
                  :loading="loading"
                  :resource="resource"
                  :route="route"
                  :hide-create="true"
                  v-if="$can('update', resource)"
                ></n-button-save-option>
              </b-col>
            </b-row>
          </n-form-confirmation>
        </validation-observer>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BFormTextarea,
  } from "bootstrap-vue";
  import Repository from "@/repositories/RepositoryFactory";
  import Ripple from "vue-ripple-directive";
  import NButtonLoading from "@/components/NButtonLoading";
  import NFormConfirmation from "@/components/NFormConfirmation";
  import NInput from "@/components/NInput";
  import FormInput from "./formInput";
  import NButtonSaveOption from "@/components/NButtonSaveOption";
  import NAsyncSingleImageUploader from "@/components/NAsyncSingleImageUploader";
  import draggable from "vuedraggable";
  const ShoppingPageRepository = Repository.get("shoppingPage");
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BFormInput,
      BButton,
      BFormTextarea,
      draggable,
      NButtonLoading,
      NFormConfirmation,
      NInput,
      NButtonSaveOption,
      NAsyncSingleImageUploader,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        shoppingPageId:null,
        data: {
          bannerId: null,
          titleEn: null,
          descriptionEn: "",
          titleKm: null,
          descriptionKm: "",
          isEnable: true,
  
          contactInformationEn:"",
          metaTitleEn: null,
          metaDescriptionEn: null,
  
          contactInformationKm:"",
          metaTitleKm: null,
          metaDescriptionKm: null,
  
          shoppingPageContents: [],
        },
        initData: {},
        loading: false,
        type: 2,
      };
    },
    mounted() {
      this.show();
    },
    methods: {
      show() {
        ShoppingPageRepository.show({})
          .then((response) => {
            const data = response?.data?.data;
            if (data) {
              this.initData = {
                ...data,
              };
              this.shoppingPageId = data.id;
              data.shoppingPageContents.forEach((content) => {
                if (content.locale == "en") {
                  this.data.contactInformationEn = content.contactInformation;
                  this.data.metaTitleEn = content.metaTitle;
                  this.data.metaDescriptionEn = content.metaDescription;
  
                } else if (content.locale == "km") {
                  this.data.contactInformationKm = content.contactInformation;
                  this.data.metaTitleKm = content.metaTitle;
                  this.data.metaDescriptionKm = content.metaDescription;
                }
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      submit(type) {
        this.$refs.editForm.validate().then((success) => {
          if (success) {
            type = this.type;
            this.$refs.btnSubmit.confirm(type);
          }
        });
      },
      save(type) {
        this.loading = true;
        this.data.shoppingPageContents = [];
        this.data.shoppingPageContents.push(
          {
            locale: "en",
            contactInformation: this.data.contactInformationEn,
            metaTitle: this.data.metaTitleEn,
            metaDescription: this.data.metaDescriptionEn,
          },
          {
            locale: "km",
            contactInformation: this.data.contactInformationKm,
            metaTitle: this.data.metaTitleKm,
            metaDescription: this.data.metaDescriptionKm,
          }
        );
        if(this.shoppingPageId !== null){
        ShoppingPageRepository.update(this.shoppingPageId, this.data)
          .then((response) => {
            // this.$refs.btnSubmit.afterSave(type, response.data.data.id);
          })
          .catch((error) => {
            if (error.response?.status == 422) {
              this.$refs.editForm.setErrors(error.response?.data?.message);
            }
          })
          .then(() => {
            this.loading = false;
          });
        }else{
            ShoppingPageRepository.create(this.data)
          .then((response) => {
            this.shoppingPageId = response.data.data.id;
            // this.$refs.btnSubmit.afterSave(type, response.data.data.id);
          })
          .catch((error) => {
            if (error.response?.status == 422) {
              this.$refs.createForm.setErrors(error.response?.data?.message);
            }
          })
          .then(() => {
            this.loading = false;
          });
        }
  
  
      },
  
    },
    setup() {
      const fields = FormInput;
      const resource = "shopping-page";
      const route = "shopping-page";
  
      return { fields,route ,resource};
    },
  };
  </script>